import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import SectionTitle from "../../atoms/section-title/section-title"
import "./avocat-section.scss"
import jp from "./JP.png"

const AvocatSection = ({ name, who, className, children }) => {
  let img
  switch (who) {
    case "jp":
      img = jp
      break
    default:
  }

  return (
    <section className={classnames("section", "avocat-section", className)}>
      <div className="container">
        <SectionTitle title={name} />
        <div className="content columns">
          <div className="column is-half-desktop">
            <div className="avocat-description is-family-secondary has-text-right is-size-4">
              {children}
            </div>
          </div>
          <div className="column is-half-desktop">
            <div className="avocat-image-wrapper">
              <img src={img} alt="avocat-section" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AvocatSection.propTypes = {
  name: PropTypes.string.isRequired,
  who: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default AvocatSection
